import { LocaleResource } from './en';

const es = {
  main: {
    navbar: {
      home: 'Inicio',
      customers: 'Clientes',
      about: 'Acerca de',
      developers: 'Desarrolladores',
      language: 'Idioma',
    },
    hero: {
      topBadge: 'Soluciones globales de pagos',
      header: 'Envía dinero a todo el mundo',
      subHeading:
        'Desde dólares y euros hasta pesos y más, Sphere lleva las conversiones de divisas al futuro.',
      ctas: {
        primary: 'Para empresas',
        secondary: 'Para individuos',
      },
    },
    solutions: {
      header: {
        topBadge: 'Procesamiento de pagos optimizado',
        header: 'Pagos internacionales para empresas',
        subHeading:
          'Mejora el movimiento de dinero—desde transferencias bancarias y tarjetas hasta el soporte para todas las redes principales—\ntransferir dinero a través de internet nunca ha sido tan fácil.',
        ctas: {
          primary: 'Empieza ahora',
          secondary: 'Descubre más',
        },
        cards: {
          '0': {
            title: 'Para remesas internacionales',
            description:
              'Envía tu moneda preferida, y tus proveedores reciben USD, MXN, BRL, EUR a tasas de cambio inigualables.',
            bullets: {
              '0': 'Cumplimiento y seguridad',
              '1': 'Liquidación en tiempo real',
              '2': 'Cobertura global',
            },
          },
          '1': {
            title: 'Para individuos de alto patrimonio',
            description:
              'Servicios OTC, conversiones de divisas sin esfuerzo y soluciones completas para la gestión y seguimiento financiero.',
            bullets: {
              '0': 'Soporte dedicado 24/7',
              '1': 'Alta liquidez',
              '2': 'Privacidad y encriptación',
            },
          },
          '2': {
            title: 'Para Finanzas y FinTechs',
            description:
              'Acepta pagos como prefieras, aprovecha las conversiones automáticas en tu cuenta, y ofrece servicios de conversión personalizados para tus clientes.',
            bullets: {
              '0': 'Acuerdos de nivel de servicio',
              '1': 'Protección al cliente',
              '2': 'API y Soluciones que no se requiere código',
            },
          },
        },
      },
    },
    ramp: {
      topBadge: 'Soluciones de conversión',
      header: 'Conectando monedas globales',
      subHeading:
        'Sphere se encarga de las complejidades de ingeniería, operativas y regulatorias por ti—para que no tengas que hacerlo.',
      solutions: {
        '0': {
          title: 'Personalizado con tu marca',
          description:
            'Sphere maneja las complejidades de ingeniería, operativas y regulatorias—para que no tengas que hacerlo.',
          bullets: {
            '0': 'Disponible vía API, no-code, y widget embebible.',
            '1': 'Ahorra innumerables horas en el proceso de desarrollo.',
            '2': 'Precios inigualables',
          },
        },
        '1': {
          title: 'Conversión de monedas, fácil y rápido.',
          description:
            'Genera una billetera vinculada a tu cuenta bancaria. Envía monedas y recibe USD con un solo clic. Disponible para comerciantes seleccionados a través del panel de control de Sphere.',
        },
        '2': {
          title: 'Cumplimiento unificado',
          description:
            'Confía en nuestra experiencia global y licencias regulatorias para brindar la mejor experiencia de pagos a tus usuarios. Deja que nos encarguemos del monitoreo de transacciones, la verificación de sanciones y la detección de fraudes por ti.',
        },
      },
    },
    dashboard: {
      topBadge: 'Características',
      header: 'Panel de Sphere',
      subHeading:
        'Los enlaces de pago de Sphere son versátiles, personalizables y robustos. Se adaptan a tus necesidades, ya sea para compras únicas o suscripciones recurrentes.',
      solutions: {
        '0': {
          title: 'Enlaces de pago',
          description:
            'Acepta pagos directamente desde email, SMS, redes sociales, sitio web o aplicación. Disfruta de todo el poder de Sphere sin tener que escribir una sola línea de código.',
        },
        '1': {
          title: 'Suscripciones',
          description:
            'Ofrece suscripciones tradicionales o auténticas on-chain—directamente desde tarjeta, cuenta bancaria o billetera digital. Facturación flexible desde por minuto hasta anual, medida por asiento, y de volumen a niveles graduados. Recibe pagos directamente, sin intermediarios, unwrapping de tokens o relays de gas innecesarios.',
        },
        '2': {
          title: 'Herramientas de desarrollo',
          description:
            'Sphere simplifica tu proceso de ingeniería con funciones de pago integradas. Nos encargamos de las complejidades económicas globales, permitiendo que tus equipos se enfoquen en un desarrollo sin interrupciones en una sola plataforma.',
        },
        '3': {
          title: 'Análisis e insights de clientes',
          description:
            'Monitorea más de 15 KPIs, como MRR, churn, tasas de conversión y clics. Maximiza tus ingresos, aumenta las tasas de aprobación, recibe pagos más rápido y reduce contracargos aceptando una mayor variedad de pagos.',
        },
      },
    },
    developerTools: {
      topBadge: 'SDK',
      header: 'Kit de herramientas para desarrolladores',
      subHeading:
        'Aplicamos las mejores prácticas en pagos para ofrecer SDKs semánticos y fáciles de usar. Enfócate en hacer crecer tu negocio mientras nosotros nos encargamos de las auditorías de contratos inteligentes, la orquestación cross-chain y todo el mantenimiento técnico, operativo y de cumplimiento.',
      bullets: {
        '0': 'API RESTful simple, poderosa e intuitiva',
        '1': 'Código para copiar y pegar que simplemente funciona',
        '2': 'Documentación concisa y completa',
      },
      ctas: {
        primary: 'Documentación',
        secondary: 'Soporte al desarrollador',
      },
    },
    features: {
      header: {
        topBadge: 'Características',
        title: 'Una suite completa de pagos',
        description:
          'Conéctate de manera fluida a través de una única API o un panel fácil de usar en minutos.\nObtén acceso a cumplimiento de primer nivel, contabilidad, pagos, on/off-ramps, análisis y herramientas para desarrolladores.',
      },
      grid: {
        '0': 'Análisis completos',
        '1': 'Pagos y cobros multipartes',
        '2': 'Construcción colaborativa con ACLs basados en roles',
        '3': 'Detección de AML y fraude',
        '4': 'Seguro y certificado',
        '5': 'Notificaciones por email',
      },
      securityMarquees: {
        '0': 'OFAC\nsanciones',
        '1': 'Prácticas de mejor seguridad de FTC',
        '2': 'Prácticas de mejor seguridad de GDPR',
        '3': 'PCI\ncompliante',
      },

      benefits: {
        lowestFees: {
          title: 'Las tarifas más bajas del mercado',
          description:
            'Todas las transacciones con tarifas igualadas a las de la competencia, con las tarifas de red trasladadas al costo.',
        },
        convenientSwaps: {
          title: 'Intercambios convenientes',
          description:
            'Pagos directamente a tu cuenta bancaria o a la de un proveedor externo, de forma manual o programada, con tarifas competitivas a nivel OTC.',
        },
        instantSettlements: {
          title: 'Liquidaciones instantáneas',
          description:
            'Reciba su dinero en segundos o minutos, en lugar de días o semanas.',
        },
        splitPayouts: {
          title: 'Pagos divididos',
          description:
            'Divida los pagos automáticamente sin tarifas adicionales.',
        },
        nonCustodial: {
          title: 'No custodial',
          description:
            'Los pagos son de persona a persona y nunca manejamos fondos de clientes o comerciantes.',
        },
        audited: {
          title: 'Auditado',
          description:
            'Nuestros contratos son auditados por los mejores expertos de la industria. Regularmente realizamos modelado de amenazas, pruebas de penetración y evaluaciones de vulnerabilidades en nuestra infraestructura.',
        },
        compliant: {
          title: 'Compliance',
          description:
            'Sphere sigue todas las mejores prácticas según las regulaciones de EE.UU. y utiliza transmisores de dinero con licencia.',
        },
        encrypted: {
          title: 'Cifrado',
          description:
            'Todos los datos están cifrados y/o tokenizados en reposo y en tránsito. Nunca almacenamos PII sensible y tenemos ACLs estrictos.',
        },
      },
    },
    ctaSection: {
      header: '¿Listo para dar el siguiente paso?',
      description:
        'Forma parte de los líderes de la industria que ya están creciendo con Sphere.',
      button: 'Comenzar',
    },
    faq: {
      header: 'FAQ',
      description:
        'Preguntas frecuentes. ¿No encuentras la respuesta que buscas? Ponte en contacto con nosotros.',
      questions: {
        '0': {
          q: '¿Cuánto cuesta Sphere?',
          a: 'El acceso al software es gratuito, pero cobramos una pequeña tarifa por transacción. Precios personalizados para empresas están disponibles a pedido.',
        },
        '1': {
          q: '¿Qué redes son compatibles?',
          a: 'Compatibilizamos con todas las principales redes de tarjetas (Visa, Mastercard, American Express, Discover) en más de 120 países, transferencias bancarias y transferencias nacionales e internacionales.',
        },
        '2': {
          q: '¿Cómo se maneja la conversión de divisas?',
          a: 'Los clientes consienten en cambiar a una moneda diferente durante la transferencia, que se enruta a través de un intercambio o está sujeta a tasas de cambio al costo.',
        },
        '3': {
          q: '¿Sphere es auditado?',
          a: 'Sí, somos auditados por OtterSec y cumplimos con PCI-DSS. Nos ofrecemos voluntariamente para cumplir con los requisitos de MSB/VASP y empleamos un programa sólido de KYC/AML. Estamos en proceso de recibir nuestras certificaciones SOC2 e ISO/IEC 27001.',
        },
        '4': {
          q: '¿Sphere custodia fondos?',
          a: 'Nunca. Somos un proveedor de servicios tecnológicos, no un MSB/VASP ni un transmisor de dinero. Todos los pagos que implican custodia son manejados por transmisores de dinero con licencia, custodios calificados y bancos con licencia.',
        },
        '5': {
          q: '¿Es posible integrar Sphere con mi solución de pago existente?',
          a: 'Sí. Puedes usar tu propio Stripe u otros proveedores de pago, y usar Sphere para agregar soporte para pagos alternativos y automatización de back-office a través de nuestra API/SDK. Diseñamos nuestra API para usar flujos de datos y modelos de objetos similares para reducir la cantidad de infraestructura que se debe reescribir.',
        },
      },
    },
    footer: {
      slogan: 'Acelerando las monedas digitales para un mundo más conectado.',
      termsOfService: 'Términos de Servicio',
      privacyPolicy: 'Política de Privacidad',
    },
  },

  general: {
    comingSoon: 'Próximamente',
    support: 'Soporte',
  },
} as const satisfies LocaleResource;

export default es;
