import { InitOptions } from 'i18next';
import type { Language, Namespace } from '.';
import en from './resources/en';
import es from './resources/es';
import pt from './resources/pt';

const resources = {
  en,
  es,
  pt,
} as const;

const cookieName = 'i18next';
const fallbackLng = 'en' satisfies Language;
const languages: Language[] = [fallbackLng, 'es', 'pt'];
const namespaces: Namespace[] = ['main', 'general'];
const defaultNS = 'main' satisfies Namespace;

function getOptions(
  lng: InitOptions['lng'] = fallbackLng,
  ns: InitOptions['ns'] = defaultNS,
): InitOptions {
  return {
    resources,
    fallbackLng,
    supportedLngs: languages,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    interpolation: { escapeValue: false },
  };
}

export {
  defaultNS,
  fallbackLng,
  languages,
  namespaces,
  cookieName,
  getOptions,
};
