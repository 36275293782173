'use client';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronDownIcon, LanguagesIcon } from 'lucide-react';
import React from 'react';

import { Language } from '@/i18n';
import { LanguageSelector } from './LanguageSelector';

// TODO: Remove this once Radix UI fixes the issue with the dropdown menu
declare module '@radix-ui/react-dropdown-menu' {
  interface DropdownMenuContentProps {
    sideOffset?: number;
    align?: 'start' | 'end';
    className?: string;
    children: React.ReactNode;
  }
}

const LANGUAGES_LIST = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es', native: 'Español' },
  { label: 'Portuguese', value: 'pt', native: 'Português' },
];
interface LanguageDropdownProps {
  lang: Language;
}
const LanguageDropdown: React.FC<LanguageDropdownProps> = ({ lang }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className='text-subtle flex items-center gap-x-2 px-4 py-2 text-[0.9375rem] font-medium min-w-[11rem] ml-auto -mr-12 max-lg:hidden'>
          <LanguagesIcon className='size-4' />
          <span className='text-variant'>
            {LANGUAGES_LIST.find((item) => item.value === lang)?.label}
          </span>
          <ChevronDownIcon className='size-4' />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align='start'
          className='min-w-[200px] bg-background rounded-md shadow-sphere-soft p-4 px-2 border border-t-0 !rounded-t-none border-outline data-[state=closed]:animate-dropdown-slide-up data-[state=open]:animate-dropdown-slide-down origin-[var(--radix-dropdown-menu-content-transform-origin)] max-h-[var(--radix-dropdown-menu-content-available-height)] max-lg:hidden'
          sideOffset={5}
        >
          <LanguageSelector lang={lang} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export { LanguageDropdown };
