'use client';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useCookies } from 'react-cookie';
import {
  I18nextProvider,
  initReactI18next,
  useTranslation as useTranslationOrg,
} from 'react-i18next';
import { Language } from '.';
import { cookieName, getOptions, languages } from './settings';

import { LanguageProps } from './types';

const initializeI18next = async () => {
  try {
    if (!i18next.isInitialized) {
      await i18next
        .use(initReactI18next)
        .use(LanguageDetector)
        .use(
          resourcesToBackend((language: Language) =>
            import(`./resources/${language}`).then((mod) => mod.default),
          ),
        )
        .init({
          ...getOptions(),
          lng: undefined,
          detection: {
            order: ['path', 'htmlTag', 'cookie', 'navigator'],
          },
          preload: typeof window === 'undefined' ? languages : [],
        });
    }
  } catch (error) {
    console.error('Error initializing i18next:', error);
  }
};

// Call the initialization function
initializeI18next();

export type I18nProviderProps = {
  children: React.ReactNode;
} & LanguageProps;

export const I18nProvider = ({ children, lang }: I18nProviderProps) => {
  // const { i18n } = useTranslation('main', lang);
  const [cookies, setCookie] = useCookies([cookieName]);
  const { t, i18n } = useTranslationOrg('main');
  const activeLngRef = useRef(i18n.resolvedLanguage);

  const i18nMemoized = useMemo(() => i18n, [i18n]);

  if (typeof window === 'undefined' && lang && i18n.resolvedLanguage !== lang) {
    i18n.changeLanguage(lang);
  }
  // biome-ignore lint/correctness/useExhaustiveDependencies: setCookie doens't change
  const handleLanguageChange = useCallback(
    (lang: Language) => {
      i18n
        .changeLanguage(lang)
        .then(() => {
          console.log('resolved', i18n.resolvedLanguage);
          activeLngRef.current = i18n.resolvedLanguage;
        })
        .catch((error) => {
          console.error('Error changing language:', error);
        });
    },
    [lang, i18n, cookies.i18next],
  );

  useEffect(() => {
    if (cookies.i18next !== lang) {
      setCookie(cookieName, lang, { path: '/' });
    }
  }, [lang, cookies.i18next]);

  useEffect(() => {
    if (lang && i18n.resolvedLanguage !== lang) {
      handleLanguageChange(lang);
    }
  }, [handleLanguageChange, lang, i18n.resolvedLanguage]);

  return <I18nextProvider i18n={i18nMemoized}>{children}</I18nextProvider>;
};
